import React from 'react'

import LinesEllipsis from 'react-lines-ellipsis'

import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const AuthorLink = ({ author }) => {

  return (
    <div className="box">
      <div className="columns ">

        {author.node.picture &&
          <div className="column">
            <GatsbyImage
              image={getImage(author.node.picture.localFile)}
              alt={author.node.name}
            />
          </div>
        }

        <div className="column">
          <div className="content">
            <h3 className="title is-3">{author.node.name}</h3>
            <LinesEllipsis
              text={author.node.bio}
              maxLine='3'
            />
          </div>
          <Link to={`/${author.node.slug}`} className="button is-info">
            Lees meer
          </Link>
        </div>
      </div>
    </div>
  )
}

export default AuthorLink
