import React from 'react'
import AuthorLink from './AuthorLink'

const WritersRow = ({ authors }) => (
  <div className="columns">
    {authors.map((author, i) => (
      <div className="column is-half" key={i}>
        <AuthorLink
          author={author}
        />
      </div>
    ))}
  </div>
)

export default WritersRow
