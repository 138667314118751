import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'

import Hero from '../components/Hero'
import Seo from '../components/Seo'
import AuthorsRow from '../components/AuthorsRow'

const AuthorsPage = ({data}) => {
  const authors = data.allStrapiWriter.edges
  const title = "Wie zijn wij"

  return (
    <Layout>
      <Seo title={title}  />
      <Hero title={title} />
      <section className="section">
        <div className="container">
          {/* <h1 className="title">{title}</h1> */}
          <div className="content">
            <AuthorsRow authors={authors} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    allStrapiWriter {
      edges {
        node {
          strapiId
          slug
          name
          bio
          picture {
            localFile{
              childImageSharp {
                gatsbyImageData(width: 300, height: 300)
              }
            }
          }
        }
      }
    }
  }
`

export default AuthorsPage
